<template>
	<DefaultLayout :has-side-nav="false" />
</template>

<script>
import { mapActions } from 'vuex';
import { DefaultLayout, AuthService } from '@workflow-solutions/siteflow-section-layout';

export default {
	components: {
		DefaultLayout
	},
	mounted() {
		this.logout();
		AuthService.hpIdLogoutRedirect(1);
	},
	methods: {
		...mapActions({
			logout: 'user/logout'
		})
	}
};
</script>
