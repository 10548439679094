<template>
	<DefaultLayout :has-side-nav="false">
		<section class="Home-searchBox">
			<div class="Home-searchBox-leftPlaceholder" />
			<div class="Home-searchBox-inputWrapper" @click="openSearch()">
				<b-input type="search" readonly="readonly" :placeholder="$t('Search...')" />
			</div>
		</section>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
	DefaultLayout,
	hpIdTempTokenLoginMixin,
	loginRedirectMixin,
	oldUiAuthCompatibilityMixin,
	AuthService
} from '@workflow-solutions/siteflow-section-layout';

export default {
	components: {
		DefaultLayout
	},
	mixins: [
		loginRedirectMixin(mapGetters),
		hpIdTempTokenLoginMixin(mapGetters, mapActions),
		oldUiAuthCompatibilityMixin(mapMutations)
	],
	data() {
		return {
			ready: false
		};
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'user/isAuthenticated'
		}),
		isHPIdTokenProvided() {
			return !!this.$router.currentRoute.query?.token;
		}
	},
	watch: {
		isAuthenticated(value) {
			if (value) this.handleGetVars();
		}
	},
	async created() {
		// Authenticate production user if token is provided in local storage
		if (!this.isAuthenticated && AuthService.isAuthenticated()) {
			try {
				const token = AuthService.getToken();
				await this.login({ token });
			} catch (err) {
				console.log('An error occurred while production user auth', err);
			}
		}

		// Go to the login page if user is not authenticated and hp id token is not provided in the route query
		if (!this.isAuthenticated && !this.isHPIdTokenProvided) {
			this.$router.push({ name: 'login', query: this.$route.query });
			return;
		}

		await this.handleGetVars();
	},
	methods: {
		...mapActions({
			openSearch: 'search/showSearchModal',
			getVars: 'account/getVars'
		}),
		async handleGetVars() {
			if (!this.isAuthenticated) return;

			await this.getVars().catch(err => console.error('Error loading vars', err));
			this.ready = true;
		}
	}
};
</script>

<style lang="scss" type="text/scss">
.Home-searchBox {
	// Some styles from OneflowTopBar of ofs-vue-layout are repeated here
	display: flex;
	flex-direction: row;
	width: 100vw;

	// The left placeholder mostly repeats styles of OneflowTopBar-Logo from ofs-vue-layout
	&-leftPlaceholder {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		padding: 0em;
		min-width: 200px;
		align-items: center;
	}

	&-inputWrapper {
		width: 100%;
		padding: 50px 15px 0 0;

		input {
			padding: 10px 15px;
			font-size: 20px;

			&[readonly] {
				background: white;
			}
		}
	}
}
</style>
