<template>
	<div class="LoginPage d-flex m-0 p-0 justify-content-center">
		<b-form class="LoginPage-form" novalidate @submit.prevent="handleSmartCardLoginRequest">
			<template v-if="isProductionStation">
				<div class="LoginPage-hpid-header d-flex justify-content-center">
					<img class="LoginPage-logo" src="images/hp-sf-logo.svg" alt="HP SiteFlow Logo" />
				</div>
				<b-card class="LoginPage-production-card" header-tag="header" body-class="body">
					<h2 class="LoginPage-production-title text-center">{{ $t('Production sign in') }}</h2>
					<of-form-input
						name="smartCardNumber"
						type="text"
						:placeholder="$t('Smart card number')"
						no-label
						required
					/>
					<b-button
						v-t="'Log in'"
						class="w-100 LoginPage-production-btn"
						size="lg"
						variant="primary"
						@click="handleSmartCardLoginRequest"
					/>
					<div class="text-center">
						<b-button
							variant="link"
							class="w-100 LoginPage-production-link"
							size="sm"
							v-t="'Standard Log In'"
							@click="handleLoginWithHPID"
						/>
					</div>
				</b-card>
			</template>
			<template v-else>
				<div class="LoginPage-hpid-header d-flex justify-content-center">
					<Loader v-if="isHp" class="LoginPage-hpid-loader" />
					<img v-else class="LoginPage-logo" src="images/hp-sf-logo.svg" alt="HP SiteFlow Logo" />
				</div>
				<h2 v-if="isHp" class="LoginPage-hpid-title text-center">{{ $t('Loading Production Management') }}</h2>
				<b-card v-else header-tag="header" body-class="body">
					<b-card-text>
						<h2 class="LoginPage-hpid-title text-center">
							{{ $t('Sign in with your') }} {{ $t('HP account') }}
						</h2>
						<template v-if="!standardLogin">
							<p class="text-center LoginPage-rerouting-title">{{ $t('Re-routing...') }}</p>
							<p class="text-center LoginPage-rerouting-message">
								{{ $t('If it is taking more than three seconds, please click the below button.') }}
							</p>
						</template>
						<b-button
							v-t="'Sign in'"
							class="w-100 LoginPage-hpid-btn"
							variant="primary"
							size="lg"
							@click="handleLoginWithHPID"
						/>
					</b-card-text>
				</b-card>
			</template>
		</b-form>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';
import { localStorage, Loader, AuthService } from '@workflow-solutions/siteflow-section-layout';
import { $t } from '../../vuex';

const AUTOMATIC_HPID_REDIRECT_DELAY = 3000; // 3 seconds

export default {
	components: {
		Loader,
		OfFormInput
	},
	mixins: [withForm('loginForm')],
	computed: {
		...mapGetters({
			isAuthenticated: 'user/isAuthenticated',
			isActive: 'featureToggle/isActive',
			lang: 'lang/lang'
		}),
		redirectTo() {
			const { redirectTo } = this.$route.query || {};
			return redirectTo;
		},
		productionAccountId() {
			return localStorage.get('productionAccountId') || localStorage('ngStorage-productionAccountId');
		},
		productionStation() {
			return localStorage.get('productionStation') || localStorage('ngStorage-productionStation');
		},
		isProductionStation() {
			return this.productionAccountId && this.productionStation === 'production';
		},
		isHp() {
			return window.$config.isHp;
		},
		smartCardLoginPage() {
			return this.$route.name === 'production-login';
		},
		standardLogin () {
			return this.$route.query.standard_login === '1';
		}
	},
	async created() {
		await this.$nextTick();

		if (this.isAuthenticated) {
			this.$router.push({ name: 'home' });
			return;
		}

		if (!this.smartCardLoginPage && this.isProductionStation) {
			// if trying to access the login, the app is production station => redirect to smart card login
			this.$router.push({ name: 'production-login' });
			this.$notify({
				type: 'warn',
				title: $t('Warning'),
				text: $t('The browser is configured as a production station')
			});
			return;
		}

		if (this.smartCardLoginPage && !this.isProductionStation) {
			// if trying to access the smart card login, and the app is not setup => redirect to regular login
			this.$router.push({ name: 'login' });
			this.$notify({
				type: 'error',
				title: $t('Error'),
				text: $t('The browser is not configured as a production station')
			});
		}

		if (this.standardLogin) return;

		// Automatic HPID login redirect
		if (!this.smartCardLoginPage && !this.isProductionStation) {
			setTimeout(() => this.handleLoginWithHPID(), AUTOMATIC_HPID_REDIRECT_DELAY);
		}
	},
	methods: {
		...mapActions({
			loginProduction: 'auth/loginProduction'
		}),
		async handleSmartCardLoginRequest() {
			try {
				if (!this.formData.smartCardNumber) {
					this.$notify({
						type: 'error',
						title: $t('Error'),
						text: $t('Smart card number is not provided')
					});
					return;
				}

				const env = window.$config.environment || 'live';
				const token = await this.loginProduction({
					smartCardNumber: this.formData.smartCardNumber,
					accountId: this.productionAccountId,
					env
				});

				AuthService.setToken(token);
				this.resetFormData();
				this.$router.push({ name: 'home' });
			} catch (err) {
				this.$notify({
					type: 'error',
					title: $t('Error'),
					text: `${$t('An error occurred while login')}: ${this.$errorUtils.displayError(err)}`
				});
				throw err;
			}
		},
		handleLoginWithHPID() {
			window.location.href = `${window.$config.hpIdAuthorize}&locale=${this.lang}`;
		}
	}
};
</script>

<style lang="scss">
@import '../../styles/shared';

.LoginPage {
	height: 100vh;
	width: 100vw;

	&-form {
		width: 30%;
	}

	&-production-btn {
		margin-top: 1.5 * $spacer;
		margin-bottom: 1.5 * $spacer;
	}

	&-production-title,
	&-hpid-title {
		margin-bottom: 1.5 * $spacer;
	}

	&-hpid-btn,
	&-hpid-title,
	&-production-title,
	&-production-btn {
		font-size: $of-font-size-large;
	}

	&-rerouting-message,
	&-production-link {
		font-size: $of-font-size-normal;
	}

	&-rerouting-title{
		font-size: $of-font-size-smaller;
	}

	&-hpid-header,
	&-production-header {
		padding: $spacer;
	}

	&-logo,
	&-hpid-loader {
		height: 2 * $spacer;
	}
}
</style>
